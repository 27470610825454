// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import './index.css';
// import App from './App';
// import reportWebVitals from './reportWebVitals';

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-circular-progressbar/dist/styles.css";
// import { ToastContainer } from 'react-toastify';
import { Toaster } from "react-hot-toast";
import * as serviceWorker from "./serviceWorker";
import AttendanceContextProvider from "./Context/AttendanceContext/AttendanceContextProvider";
import { SidebarProvider } from "./Context/AttendanceContext/smallSidebarcontext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <SidebarProvider>
      <AttendanceContextProvider>
        <App />
      </AttendanceContextProvider>
    </SidebarProvider>
    <Toaster
      position="top-center"
      reverseOrder={false}
      gutter={8}
      containerClassName=""
      containerStyle={{}}
      toastOptions={{
        // Define default options
        className: "",
        duration: 5000,
        style: {
          background: "#363636",
          color: "#fff"
        },

        // Default options for specific types
        success: {
          duration: 3000,
          theme: {
            primary: "green",
            secondary: "black"
          }
        }
      }}
    />{" "}
  </React.StrictMode>

  // <React.StrictMode>
  //   <AttendanceContextProvider>
  //     <App />
  //   </AttendanceContextProvider>
  //   <Toaster
  //     position="top-center"
  //     reverseOrder={false}
  //     gutter={8}
  //     containerClassName=""
  //     containerStyle={{}}
  //     toastOptions={{
  //       // Define default options
  //       className: "",
  //       duration: 5000,
  //       style: {
  //         background: "#363636",
  //         color: "#fff"
  //       },

  //       // Default options for specific types
  //       success: {
  //         duration: 3000,
  //         theme: {
  //           primary: "green",
  //           secondary: "black"
  //         }
  //       }
  //     }}
  //   />{" "}
  // </React.StrictMode>
);

serviceWorker.unregister();
